import React, { useState, useEffect } from "react";

const InvestmentsForm = ({
  investmentsFormData,
  handleInvestmentsChange,
  saveInvestments,
  closeInvestmentsForm,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    zakatPercentage: "",
    estimatedValue: "",
    type: "", // Add type field for validation
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(
    investmentsFormData.type === "Custom"
  );

  useEffect(() => {
    setIsFormValid(
      !validationErrors.zakatPercentage &&
        !validationErrors.estimatedValue &&
        !validationErrors.type // Include type validation
    );
  }, [validationErrors]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "zakatPercentage":
        if (isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > 1) {
          error = "Zakat percentage must be between 0 and 1";
        }
        break;
      case "estimatedValue":
        if (isNaN(value) || parseFloat(value) <= 0) {
          error = "Estimated value must be a positive number";
        }
        break;
      case "type": // Validate the type field
        if (!value) {
          error = "Type is required";
        }
        break;
      default:
        break;
    }
    setValidationErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      if (value === "Custom") {
        setIsCustomInput(true);
        handleCustomSelect(value);
      } else {
        setIsCustomInput(false);
        handleInvestmentsChange("type", value);
        const selectedOption = e.target.options[e.target.selectedIndex];
        const zakatPercentage = selectedOption.getAttribute("zakatPercentage");
        handleInvestmentsChange("zakatPercentage", zakatPercentage);
      }
    } else {
      handleInvestmentsChange(name, value);
    }
    validateInput(name, value);
  };

  const calculateValues = () => {
    const zakatPercentage = parseFloat(investmentsFormData.zakatPercentage);
    if (!isNaN(zakatPercentage)) {
      const estimatedValue = parseFloat(investmentsFormData.estimatedValue); // Parse estimatedValue to float
      const zakatPayable = (estimatedValue * (zakatPercentage / 100)).toFixed(
        2
      );
      return { estimatedValue, zakatPayable };
    }
    return { estimatedValue: "", zakatPayable: "" };
  };

  const handleCustomSelect = (value) => {
    handleInvestmentsChange("type", value);
    setIsCustomInput(true);
  };

  return (
    <div className="addnewitemform-overlay">
      <div className="addnewitemform-container">
        <h2>Add Investments</h2>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="type">Type:</label>
              {isCustomInput ? (
                <input
                  type="text"
                  id="type"
                  name="type"
                  value={investmentsFormData.type}
                  onChange={handleInputChange}
                  className={`custom-type-input ${
                    validationErrors.type ? "invalid" : ""
                  }`}
                  placeholder="Enter custom type"
                />
              ) : (
                <select
                  id="type"
                  name="type"
                  value={investmentsFormData.type}
                  onChange={handleInputChange}
                  className={`type-select ${
                    validationErrors.type ? "invalid" : ""
                  }`}
                >
                  <option value="">Select an Item</option>
                  <option
                    value="Loans from Friends/Relatives"
                    zakatPercentage="2.5%"
                  >
                    Loans from Friends/Relatives
                  </option>
                  <option value="Mutual Funds" zakatPercentage="2.5%">
                    Mutual Funds
                  </option>
                  <option
                    value="Provident Fund Contributions"
                    zakatPercentage="2.5%"
                  >
                    Provident Fund Contributions
                  </option>
                  <option
                    value="Insurance Premiums incl. bonus"
                    zakatPercentage="2.5%"
                  >
                    Insurance Premiums incl. bonus
                  </option>
                  <option
                    value="Value of Shares incl. Dividends (Market Value)"
                    zakatPercentage="2.5%"
                  >
                    Value of Shares incl. Dividends (Market Value)
                  </option>
                  <option
                    value="Private Business Investments"
                    zakatPercentage="2.5%"
                  >
                    Private Business Investments
                  </option>

                  <option value="Custom">Custom</option>
                </select>
              )}
              {validationErrors.type && (
                <p className="error-message">{validationErrors.type}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="estimatedValue">Estimated Value:</label>
              <input
                type="text"
                id="estimatedValue"
                name="estimatedValue"
                value={investmentsFormData.estimatedValue}
                onChange={handleInputChange}
                className={validationErrors.estimatedValue ? "invalid" : ""}
              />
              {validationErrors.estimatedValue && (
                <p className="error-message">
                  {validationErrors.estimatedValue}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="zakatPercentage">Zakat Percentage:</label>
              <input
                type="text"
                id="zakatPercentage"
                name="zakatPercentage"
                value={investmentsFormData.zakatPercentage}
                onChange={handleInputChange}
                className={validationErrors.zakatPercentage ? "invalid" : ""}
              />
              {validationErrors.zakatPercentage && (
                <p className="error-message">
                  {validationErrors.zakatPercentage}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="zakatPayable">Zakat Payable:</label>
              <input
                type="text"
                id="zakatPayable"
                name="zakatPayable"
                value={calculateValues().zakatPayable}
                onChange={(e) =>
                  handleInvestmentsChange("zakatPayable", e.target.value)
                }
              />
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={saveInvestments}
              disabled={!isFormValid}
              className="additem-button"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeInvestmentsForm}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
          <div className="user-notes">
            <p className="guideline-heading">Guidelines:</p>
            <ul>
              <li className="guide-label">
                <span className="label-bold">Type:</span> Select or enter the
                type of investment item.
              </li>
              <li className="guide-label">
                <span className="label-bold">Estimated Value:</span> Enter the
                estimated value of the investment item.
              </li>
              <li className="guide-label">
                <span className="label-bold">Zakat Percentage:</span> Enter the
                zakat percentage applicable to the investment item (in decimal
                format, e.g., 0.025 for 2.5%).
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvestmentsForm;
