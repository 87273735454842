import React, { useState, useEffect } from "react";
import ZakatInstructionData from "./ZakatInstructionData"; // Import the data file
import "./ZakatInstruction.css";

function ZakatInstruction({ onClose }) {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) { // Adjust scroll position as needed
      setShowButton(true);
      setTimeout(() => setShowButton(false), 5000);
    }
  };

  return (
    <div className="instruction-popup">
      <div className="instruction-container">
        <h2>{ZakatInstructionData.intro.title}</h2>
        <p>{ZakatInstructionData.intro.content}</p>

        {ZakatInstructionData.details.map((detail, index) => (
          <div key={index} className="ZI-details-container">
            <h4>{index + 1}. {detail.title}</h4>
            {detail.content.map((paragraph, idx) => (
              <p key={idx}>{paragraph}</p>
            ))}
          </div>
        ))}

        <button className={`ZI-continue-button ${showButton ? 'show-button' : ''}`} onClick={onClose}>
          Continue
        </button>
        <p className="footnote">
          NOTE:if anything purchased but not crossed a year , zakat is not liable for that
        </p>
        <p className="footnote">
          FOOTNOTE: Please note that Interest in any form is Haram and strictly
          prohibited. So please stay away from taking Loans on Interests AND
          Collection of Interests from any body or institution.
        </p>
      </div>
    </div>
  );
}

export default ZakatInstruction;
