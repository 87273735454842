// PartnershipDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const PartnershipDetail = ({
  partnership,
  index,
  openPartnershipForm,
  openDeleteConfirmation,
}) => {
  return (
    <div key={index} className="zakatCalculation-row entry-separation-line">
      <div className="zakatCalculation-value">{partnership.name}</div>
      <div className="zakatCalculation-value">{partnership.capitalBalance}</div>
      <div className="zakatCalculation-value">{partnership.loansAdvanced}</div>
      <div className="zakatCalculation-value">{partnership.withdrawals}</div>
      <div className="zakatCalculation-value">{partnership.accumulatedProfit}</div>
      <div className="zakatCalculation-value">{partnership.nettTotalWorth}</div>
   
      <div className="zakatCalculation-value">{partnership.zakatPayable}</div>
      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openPartnershipForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "partnership")}
        />
      </div>
    </div>
  );
};

export default PartnershipDetail;
