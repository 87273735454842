import React, { useState, useEffect } from "react";
import "./GoldForm.css";

const GoldForm = ({
  goldFormData,
  handleGoldChange,
  saveGold,
  closeGoldForm,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    weight: "",
    valuePerGram: "",
    zakatPercentage: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(
    goldFormData.type === "Custom"
  );

  useEffect(() => {
    setIsFormValid(
      !validationErrors.weight &&
        !validationErrors.valuePerGram &&
        !validationErrors.zakatPercentage
    );
  }, [validationErrors]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "weight":
      case "valuePerGram":
        if (isNaN(value) || parseFloat(value) < 0) {
          error = "Value must be a positive number";
        }
        break;
      case "zakatPercentage":
        if (isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > 1) {
          error = "Zakat percentage must be between 0 and 1";
        }
        break;
      default:
        break;
    }
    setValidationErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      if (value === "Custom") {
        setIsCustomInput(true);
        handleCustomSelect(value);
      } else {
        setIsCustomInput(false);
        handleGoldChange("type", value);
        const selectedOption = e.target.options[e.target.selectedIndex];
        const zakatPercentage = selectedOption.getAttribute("zakatPercentage");
        handleGoldChange("zakatPercentage", zakatPercentage);
      }
    } else {
      handleGoldChange(name, value);
    }
    validateInput(name, value);
  };

  const calculateValues = () => {
    const weight = parseFloat(goldFormData.weight);
    const valuePerGram = parseFloat(goldFormData.valuePerGram);
    const zakatPercentage = parseFloat(goldFormData.zakatPercentage);
    if (!isNaN(weight) && !isNaN(valuePerGram) && !isNaN(zakatPercentage)) {
      const estimatedValue = (weight * valuePerGram).toFixed(2);
      const zakatPayable = (estimatedValue * (zakatPercentage / 100)).toFixed(
        2
      );
      return { estimatedValue, zakatPayable };
    }
    return { estimatedValue: "", zakatPayable: "" };
  };

  const handleCustomSelect = (value) => {
    handleGoldChange("type", value);
    setIsCustomInput(true);
  };

  return (
    <div className="addnewitemform-overlay">
      <div className="addnewitemform-container">
        <h2>Add Gold</h2>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="type">Type:</label>
              {isCustomInput ? (
                <input
                  type="text"
                  id="type"
                  name="type"
                  value={goldFormData.type}
                  onChange={handleInputChange}
                  className="custom-type-input"
                  placeholder="Enter custom type"
                />
              ) : (
                <select
                  id="type"
                  name="type"
                  value={goldFormData.type}
                  onChange={handleInputChange}
                  className="type-select"
                >
                  <option value="">Select an Item</option>
                  <option value="24 Carat Gold" zakatPercentage="2.5%">
                    24 Carat Gold
                  </option>
                  <option value="22 Carat Gold" zakatPercentage="2.5%">
                    22 Carat Gold
                  </option>
                  <option value="18 Carat Gold" zakatPercentage="2.5%">
                    18 Carat Gold
                  </option>
                  <option value="Custom">Custom</option>
                </select>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="weight">Weight:</label>
              <input
                type="text"
                id="weight"
                name="weight"
                value={goldFormData.weight}
                onChange={handleInputChange}
                className={validationErrors.weight ? "invalid" : ""}
              />
              {validationErrors.weight && (
                <p className="error-message">{validationErrors.weight}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="valuePerGram">Value Per Gram:</label>
              <input
                type="text"
                id="valuePerGram"
                name="valuePerGram"
                value={goldFormData.valuePerGram}
                onChange={handleInputChange}
                className={validationErrors.valuePerGram ? "invalid" : ""}
              />
              {validationErrors.valuePerGram && (
                <p className="error-message">{validationErrors.valuePerGram}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="zakatPercentage">Zakat Percentage:</label>
              <input
                type="text"
                id="zakatPercentage"
                name="zakatPercentage"
                value={goldFormData.zakatPercentage}
                onChange={handleInputChange}
                className={validationErrors.zakatPercentage ? "invalid" : ""}
              />
              {validationErrors.zakatPercentage && (
                <p className="error-message">
                  {validationErrors.zakatPercentage}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="estimatedValue">Estimated Value:</label>
              <input
                type="text"
                id="estimatedValue"
                name="estimatedValue"
                value={calculateValues().estimatedValue}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="zakatPayable">Zakat Payable:</label>
              <input
                type="text"
                id="zakatPayable"
                name="zakatPayable"
                value={calculateValues().zakatPayable}
                readOnly
              />
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={saveGold}
              disabled={!isFormValid}
              className="additem-button"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeGoldForm}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
          <div class="user-notes">
            <p class="guideline-heading">Guidelines:</p>
            <ul>
              <li class="guide-label">
                <span class="label-bold">Type:</span> Select or enter the type
                of gold item.
              </li>
              <li class="guide-label">
                <span class="label-bold">Weight:</span> Enter the weight of the
                gold item in grams.
              </li>
              <li class="guide-label">
                <span class="label-bold">Value Per Gram:</span> Enter the value
                of the gold per gram in your currency.
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoldForm;
