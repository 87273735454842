import React from "react";
import Report from "./Report";

const ReportPage = () => {
  // Retrieve zakat calculation data from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const userName = urlParams.get("userName");
  const currentGoldValue = urlParams.get("currentGoldValue");
  const nisabValue = urlParams.get("nisabValue");
  const partnershipDetails = JSON.parse(urlParams.get("partnership"));
  const goldDetails = JSON.parse(urlParams.get("gold"));
  const silverDetails = JSON.parse(urlParams.get("silver"));
  const businessDetails = JSON.parse(urlParams.get("business"));
  const preciousStonesDetails = JSON.parse(urlParams.get("preciousStones"));
  const cashDetails = JSON.parse(urlParams.get("cash"));
  const landDetails = JSON.parse(urlParams.get("land"));
  const investmentsDetails = JSON.parse(urlParams.get("investments"));
  const agriculturalDetails = JSON.parse(urlParams.get("agricultural"));
  const animalandPoultryDetails = JSON.parse(urlParams.get("animalandPoultry"));
  const generalLiabilitiesDetails = JSON.parse(
    urlParams.get("generalLiabilities")
  );

  return (
    <div>
      <Report
        userName={userName}
        currentGoldValue={currentGoldValue}
        nisabValue={nisabValue}
        partnershipDetails={partnershipDetails}
        goldDetails={goldDetails}
        silverDetails={silverDetails}
        businessDetails={businessDetails}
        preciousStonesDetails={preciousStonesDetails}
        cashDetails={cashDetails}
        landDetails={landDetails}
        investmentsDetails={investmentsDetails}
        agriculturalDetails={agriculturalDetails}
        animalandPoultryDetails={animalandPoultryDetails}
        generalLiabilitiesDetails={generalLiabilitiesDetails}
      />
    </div>
  );
};

export default ReportPage;
