
import React, { useEffect } from 'react';
import { TweenMax } from 'gsap'; // Import TweenMax from gsap
import "./MoonAnimation.css"
const MoonAnimation = () => {
  useEffect(() => {
    // Run the animation when the component mounts
    TweenMax.to("#overlay", 8, {
      attr: {
        cx: "300"  
      },
      ease: "slow(0.1, 0.9)", // Use easing function directly
      repeat: -1,
      repeatDelay: 2 // Corrected the syntax for repeat delay
    });
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
   <div className='moonanimation-main'>
     <svg width="200" height="200">
      <clipPath id="clip1">
        <circle cx="100" cy="100" r="90" />
      </clipPath>
      <circle cx="100" cy="100" r="90" fill="#e0e0e0" />
      <circle clipPath="url('#clip1')" cx="85" cy="83" r="95" fill="white" />
      <circle cx="100" cy="100" r="90" stroke-width="2" stroke="#24d4db" fill="none" />
      <ellipse cx="63" cy="45" rx="11" ry="24" fill="#c2c2c2" transform="rotate(27)" />
      <ellipse cx="140" cy="-25" rx="31" ry="18" fill="#c2c2c2" transform="rotate(30)" />
      <ellipse cx="45" cy="150" rx="17" ry="13" fill="#c2c2c2" transform="rotate(-5)" />
      <circle id="overlay" clipPath="url('#clip1')" cx="-100" cy="100" r="90" fill="#24d4db" stroke-width="2" stroke="#24d4db" />
    </svg>
   </div>
  );
};

export default MoonAnimation;