// ZakatEligible.js

import React from "react";
import "./ZakatEligible.css";

const ZakatEligible = ({ onClose }) => {
  return (
    <div className="popup">
      <div className="popup-inner">
        <h2 className="eligibility-title">Not Eligible for Zakat</h2>
        <p className="eligibility-message">
          Unfortunately, you are not eligible to pay Zakat at the moment. Here's why:
        </p>
        <ul className="eligibility-reasons">
          <li>
            Your total Zakat payable is less than the Nisab value.
          </li>
          <li>
            Zakat is only obligatory if your assets meet or exceed the Nisab threshold.
          </li>
          <li>
            The Nisab value represents the minimum amount of wealth required for Zakat to be obligatory.
          </li>
        </ul>
        <p className="blessing-message">
          Insha'Allah, may Allah increase your financial wealth and grant you the opportunity to fulfill one of the pillars of Islam (Zakat).
        </p>
        <button className="close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ZakatEligible;
