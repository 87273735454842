import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import ReportPage from './Components/Home/Components/Report/ReportPage';
import MoonAnimation from './Components/Home/Components/MoonAnimation/MoonAnimation';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Set loading to false after 5 seconds

    return () => clearTimeout(timer); // Cleanup function
  }, []);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          path="/report"
          element={loading ? <MoonAnimation /> : <ReportPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;
