import React, { useState, useEffect } from "react";
import "./Home.css";
import ZakatInstruction from "./Components/ZakatInstruction/ZakatInstruction";
import ZakatCalculation from "./Components/ZakatCalculation/ZakatCalculation";
import LoadingDot from "./Components/LoadingDot/LoadingDot";
import Trustlogo from "../../trustlogo.png";
import Mrglogo from "../../mrglogo.png";
function Home() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [homeSubtitle, setHomeSubtitle] = useState("");

  useEffect(() => {
    const homesubtitleflick = () => {
      const homesubtitles = [
        "Experience the Ease of Fulfilling Your Zakat Obligations",
        "Crafted with Precision and Care by MRG Engineering",
        "Empowering You to Navigate Your Financial Responsibilities",
        "Unlock the Path to Spiritual Fulfillment and Social Impact",
      ];
      let i = 0,
        offset = 0,
        len = homesubtitles.length,
        forwards = true,
        skip_count = 0,
        skip_delay = 15,
        speed = 100;
      setInterval(() => {
        if (forwards) {
          if (offset >= homesubtitles[i].length) {
            ++skip_count;
            if (skip_count === skip_delay) {
              forwards = false;
              skip_count = 0;
            }
          }
        } else {
          if (offset === 0) {
            forwards = true;
            i++;
            offset = 0;
            if (i >= len) {
              i = 0;
            }
          }
        }
        const part = homesubtitles[i].substr(0, offset);
        if (skip_count === 0) {
          if (forwards) {
            offset++;
          } else {
            offset--;
          }
        }
        setHomeSubtitle(part);
      }, speed);
    };

    homesubtitleflick();
  }, []);

  const handleStartButtonClick = () => {
    setShowLoading(true);
    setTimeout(() => {
      setShowInstructions(true);
      setShowLoading(false);
    }, 4000); // 4 seconds delay
  };

  const handleContinueButtonClick = () => {
    setShowInstructions(false);
    setShowCalculation(true);
  };

  return (
    <div className="Home-main">
      {showInstructions ? (
        <ZakatInstruction onClose={handleContinueButtonClick} />
      ) : showCalculation ? (
        <ZakatCalculation />
      ) : showLoading ? (
        <LoadingDot />
      ) : (
        <div className="home-container">
          <div className="logo-container">
            <img src={Trustlogo} alt="Trust Logo" className="trust-logo" />
            <img src={Mrglogo} alt="Company Logo" className="company-logo" />
          </div>
          <h1 className="home-title">Welcome to the Zakat Calculator</h1>
          <div className="home-subtitle">{homeSubtitle}</div>
          <button
            className="home-start-button"
            onClick={handleStartButtonClick}
          >
            Start
          </button>
        </div>
      )}
    </div>
  );
}

export default Home;
