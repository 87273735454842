// ZakatCalculation.js

import React, { useState } from "react";
import GoldDetail from "./ZakatON/Gold/GoldDetails";
import GoldForm from "./ZakatON/Gold/GoldForm";
import SilverDetail from "./ZakatON/Silver/SilverDetails";
import SilverForm from "./ZakatON/Silver/SilverForm";
import CashDetail from "./ZakatON/Cash/CashDetails";
import CashForm from "./ZakatON/Cash/CashForm";
import InvestmentsDetail from "./ZakatON/Investments/InvestmentsDetails";
import InvestmentsForm from "./ZakatON/Investments/InvestmentsForm";
import LandDetail from "./ZakatON/Land/LandDetails";
import LandForm from "./ZakatON/Land/LandForm";
import BusinessDetail from "./ZakatON/Business/BusinessDetail";
import BusinessForm from "./ZakatON/Business/BusinessForm";
import PartnershipDetail from "./ZakatON/Partnership/PartnershipDetail";
import PartnershipForm from "./ZakatON/Partnership/PartnershipForm";
import GeneralLiabilitiesDetail from "./ZakatON/GeneralLiabilities/GeneralLiabilitiesDetail";
import GeneralLiabilitiesForm from "./ZakatON/GeneralLiabilities/GeneralLiabilitiesForm";
import AgriculturalDetail from "./ZakatON/Agricultural/AgriculturalDetails";
import AgriculturalForm from "./ZakatON/Agricultural/AgriculturalForm";
import AnimalandPoultryDetail from "./ZakatON/AnimalandPoultry/AnimalandPoultryDetails";
import AnimalandPoultryForm from "./ZakatON/AnimalandPoultry/AnimalandPoultryForm";
import PreciousStonesDetail from "./ZakatON/PreciousStones/PreciousStonesDetails";
import PreciousStonesForm from "./ZakatON/PreciousStones/PreciousStonesForm";
import ConfirmationModal from "./ConfirmationPopup/ConfirmationModal";
import { MdAddCircleOutline } from "react-icons/md";
import "./ZakatCalculation.css";
import FinalInfo from "./FinalInfo/FinalInfo";

const ZakatCalculation = () => {
  // Common state and functions for both Gold and PreciousStones
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteType, setDeleteType] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [showFinalInfo, setShowFinalInfo] = useState(false);
  const [queryString, setQueryString] = useState("");
  const handleShowReport = () => {
    setShowFinalInfo(true);
  };
  const handleSubmitFinalInfo = (userName, currentGoldValue, nisabValue) => {
    // Construct the query string with zakat calculation data, current gold value, and nisab value
    const queryString = `?userName=${userName}&currentGoldValue=${currentGoldValue}&nisabValue=${nisabValue}&partnership=${JSON.stringify(
      partnershipDetails
    )}&gold=${JSON.stringify(goldDetails)}&silver=${JSON.stringify(
      silverDetails
    )}&business=${JSON.stringify(
      businessDetails
    )}&preciousStones=${JSON.stringify(
      preciousStonesDetails
    )}&cash=${JSON.stringify(cashDetails)}&land=${JSON.stringify(
      landDetails
    )}&investments=${JSON.stringify(
      investmentsDetails
    )}&agricultural=${JSON.stringify(
      agriculturalDetails
    )}&animalandPoultry=${JSON.stringify(
      animalandPoultryDetails
    )}&generalLiabilities=${JSON.stringify(generalLiabilitiesDetails)}`;

    // Set the query string
    setQueryString(queryString);
  };

  const openDeleteConfirmation = (index, type) => {
    setDeleteIndex(index);
    setDeleteType(type);
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
    setDeleteIndex(null);
    setDeleteType(null);
  };

  const confirmDelete = () => {
    if (deleteType === "gold") {
      deleteGold(deleteIndex);
    } else if (deleteType === "preciousStones") {
      deletePreciousStones(deleteIndex);
    } else if (deleteType === "cash") {
      deleteCash(deleteIndex);
    } else if (deleteType === "silver") {
      deleteSilver(deleteIndex);
    } else if (deleteType === "investments") {
      deleteInvestments(deleteIndex);
    } else if (deleteType === "land") {
      deleteLand(deleteIndex);
    } else if (deleteType === "agricultural") {
      deleteAgricultural(deleteIndex);
    } else if (deleteType === "animalandPoultry") {
      deleteAnimalandPoultry(deleteIndex);
    } else if (deleteType === "business") {
      deleteBusiness(deleteIndex);
    } else if (deleteType === "partnership") {
      deletePartnership(deleteIndex);
    } else if (deleteType === "liabilities") {
      deleteLiabilities(deleteIndex);
    }
    closeDeleteConfirmation();
  };

  //Gold state and functions
  const [isAddingGold, setIsAddingGold] = useState(false);
  const [goldDetails, setGoldDetails] = useState([]);
  const [goldFormData, setGoldFormData] = useState({
    type: "",
    weight: "",
    valuePerGram: "",
    estimatedValue: "",
    zakatPayable: "",
  });

  const handleGoldChange = (field, value) => {
    setGoldFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const saveGold = () => {
    const newData = {
      ...goldFormData,
    };
    newData.estimatedValue = calculateEstimatedValue(
      newData.weight,
      newData.valuePerGram
    );
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate estimatedValue and zakatPayable

    if (!isEditing) {
      setGoldDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...goldDetails];
      updatedDetails[editIndex] = newData;
      setGoldDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingGold(false);
    resetGoldFormData(); // Reset form data after saving
  };

  const resetGoldFormData = () => {
    setGoldFormData({
      type: "",
      weight: "",
      valuePerGram: "",
      estimatedValue: "",
      zakatPayable: "",
    });
  };

  const deleteGold = (index) => {
    setGoldDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  const openGoldForm = (index) => {
    setIsAddingGold(true);
    setIsEditing(true);
    setEditIndex(index);
    const {
      type,
      weight,
      valuePerGram,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    } = goldDetails[index];
    setGoldFormData({
      type,
      weight,
      valuePerGram,
      estimatedValue,
      zakatPayable,
      zakatPercentage, // Ensure zakatPercentage is included
    });
  };

  //Silver state and functions
  const [isAddingSilver, setIsAddingSilver] = useState(false);
  const [silverDetails, setSilverDetails] = useState([]);
  const [silverFormData, setSilverFormData] = useState({
    type: "",
    weight: "",
    valuePerGram: "",
    estimatedValue: "",
    zakatPayable: "",
  });

  const handleSilverChange = (field, value) => {
    setSilverFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const saveSilver = () => {
    const newData = {
      ...silverFormData,
    };
    newData.estimatedValue = calculateEstimatedValue(
      newData.weight,
      newData.valuePerGram
    );
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate estimatedValue and zakatPayable

    if (!isEditing) {
      setSilverDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...silverDetails];
      updatedDetails[editIndex] = newData;
      setSilverDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingSilver(false);
    resetSilverFormData(); // Reset form data after saving
  };

  const resetSilverFormData = () => {
    setSilverFormData({
      type: "",
      weight: "",
      valuePerGram: "",
      estimatedValue: "",
      zakatPayable: "",
    });
  };

  const deleteSilver = (index) => {
    setSilverDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  const openSilverForm = (index) => {
    setIsAddingSilver(true);
    setIsEditing(true);
    setEditIndex(index);
    const {
      type,
      weight,
      valuePerGram,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    } = silverDetails[index];
    setSilverFormData({
      type,
      weight,
      valuePerGram,
      estimatedValue,
      zakatPayable,
      zakatPercentage, // Ensure zakatPercentage is included
    });
  };

  // PreciousStones state and functions
  const [isAddingPreciousStones, setIsAddingPreciousStones] = useState(false);
  const [preciousStonesDetails, setPreciousStonesDetails] = useState([]);
  const [preciousStonesFormData, setPreciousStonesFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle PreciousStones data
  const openPreciousStonesForm = (index) => {
    setIsAddingPreciousStones(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      preciousStonesDetails[index];
    setPreciousStonesFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const savePreciousStones = () => {
    const newData = {
      ...preciousStonesFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setPreciousStonesDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...preciousStonesDetails];
      updatedDetails[editIndex] = newData;
      setPreciousStonesDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingPreciousStones(false);
    resetPreciousStonesFormData(); // Reset form data after saving
  };
  const resetPreciousStonesFormData = () => {
    setPreciousStonesFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deletePreciousStones = (index) => {
    setPreciousStonesDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // Cash state and functions
  const [isAddingCash, setIsAddingCash] = useState(false);
  const [cashDetails, setCashDetails] = useState([]);
  const [cashFormData, setCashFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle Cash data
  const openCashForm = (index) => {
    setIsAddingCash(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      cashDetails[index];
    setCashFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const saveCash = () => {
    const newData = {
      ...cashFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setCashDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...cashDetails];
      updatedDetails[editIndex] = newData;
      setCashDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingCash(false);
    resetCashFormData(); // Reset form data after saving
  };
  const resetCashFormData = () => {
    setCashFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deleteCash = (index) => {
    setCashDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // Investments state and functions
  const [isAddingInvestments, setIsAddingInvestments] = useState(false);
  const [investmentsDetails, setInvestmentsDetails] = useState([]);
  const [investmentsFormData, setInvestmentsFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle Investments data
  const openInvestmentsForm = (index) => {
    setIsAddingInvestments(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      investmentsDetails[index];
    setInvestmentsFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const saveInvestments = () => {
    const newData = {
      ...investmentsFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setInvestmentsDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...investmentsDetails];
      updatedDetails[editIndex] = newData;
      setInvestmentsDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingInvestments(false);
    resetInvestmentsFormData(); // Reset form data after saving
  };
  const resetInvestmentsFormData = () => {
    setInvestmentsFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deleteInvestments = (index) => {
    setInvestmentsDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // Land state and functions
  const [isAddingLand, setIsAddingLand] = useState(false);
  const [landDetails, setLandDetails] = useState([]);
  const [landFormData, setLandFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle Land data
  const openLandForm = (index) => {
    setIsAddingLand(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      landDetails[index];
    setLandFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const saveLand = () => {
    const newData = {
      ...landFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setLandDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...landDetails];
      updatedDetails[editIndex] = newData;
      setLandDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingLand(false);
    resetLandFormData(); // Reset form data after saving
  };
  const resetLandFormData = () => {
    setLandFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deleteLand = (index) => {
    setLandDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // Agricultural state and functions
  const [isAddingAgricultural, setIsAddingAgricultural] = useState(false);
  const [agriculturalDetails, setAgriculturalDetails] = useState([]);
  const [agriculturalFormData, setAgriculturalFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle Agricultural data
  const openAgriculturalForm = (index) => {
    setIsAddingAgricultural(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      agriculturalDetails[index];
    setAgriculturalFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const saveAgricultural = () => {
    const newData = {
      ...agriculturalFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setAgriculturalDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...agriculturalDetails];
      updatedDetails[editIndex] = newData;
      setAgriculturalDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingAgricultural(false);
    resetAgriculturalFormData(); // Reset form data after saving
  };
  const resetAgriculturalFormData = () => {
    setAgriculturalFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deleteAgricultural = (index) => {
    setAgriculturalDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // AnimalandPoultry state and functions
  const [isAddingAnimalandPoultry, setIsAddingAnimalandPoultry] =
    useState(false);
  const [animalandPoultryDetails, setAnimalandPoultryDetails] = useState([]);
  const [animalandPoultryFormData, setAnimalandPoultryFormData] = useState({
    type: "",
    estimatedValue: "",
    zakatPercentage: "",
    zakatPayable: "",
  });

  // Functions to handle AnimalandPoultry data
  const openAnimalandPoultryForm = (index) => {
    setIsAddingAnimalandPoultry(true);
    setIsEditing(true);
    setEditIndex(index);
    const { type, estimatedValue, zakatPayable, zakatPercentage } =
      animalandPoultryDetails[index];
    setAnimalandPoultryFormData({
      type,
      estimatedValue,
      zakatPayable,
      zakatPercentage,
    });
  };

  const saveAnimalandPoultry = () => {
    const newData = {
      ...animalandPoultryFormData,
    };
    newData.zakatPayable = calculateZakatPayable(
      newData.estimatedValue,
      newData.zakatPercentage
    ); // Recalculate Zakat Payable
    if (!isEditing) {
      setAnimalandPoultryDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...animalandPoultryDetails];
      updatedDetails[editIndex] = newData;
      setAnimalandPoultryDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingAnimalandPoultry(false);
    resetAnimalandPoultryFormData(); // Reset form data after saving
  };
  const resetAnimalandPoultryFormData = () => {
    setAnimalandPoultryFormData({
      type: "",
      estimatedValue: "",
      zakatPercentage: "",
      zakatPayable: "",
    });
  };

  const deleteAnimalandPoultry = (index) => {
    setAnimalandPoultryDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  // Business
  const [isAddingBusiness, setIsAddingBusiness] = useState(false);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [businessFormData, setBusinessFormData] = useState({
    name: "",
    saleableStock: "",
    damagedStock: "",
    creditSales: "",
    payableToSuppliers: "",
    badDebts: "",
    totalStockValue: "",
    zakatPercentage: "2.5", // Fixed value
    zakatPayable: "",
  });

  const handleBusinessChange = (field, value) => {
    setBusinessFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const saveBusiness = () => {
    const newData = {
      ...businessFormData,
      totalStockValue: calculateTotalStockValue(businessFormData),
      zakatPayable: calculateZakatPayableforbusiness(businessFormData),
    };

    console.log("isEditing:", isEditing);
    console.log("editIndex:", editIndex);

    if (!isEditing) {
      setBusinessDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...businessDetails];
      updatedDetails[editIndex] = newData;
      setBusinessDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingBusiness(false);
    resetBusinessFormData(); // Reset form data after saving
  };

  const resetBusinessFormData = () => {
    setBusinessFormData({
      name: "",
      saleableStock: "",
      damagedStock: "",
      creditSales: "",
      payableToSuppliers: "",
      badDebts: "",
      totalStockValue: "",
      zakatPercentage: "2.5", // Fixed value
      zakatPayable: "",
    });
  };

  const deleteBusiness = (index) => {
    setBusinessDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  const openBusinessForm = (index) => {
    setIsAddingBusiness(true);
    setIsEditing(true); // Set isEditing to true when opening for editing
    setEditIndex(index); // Set the index of the item being edited
    const {
      name,
      saleableStock,
      damagedStock,
      creditSales,
      payableToSuppliers,
      badDebts,
      totalStockValue,
      zakatPercentage,
      zakatPayable,
    } = businessDetails[index];
    setBusinessFormData({
      name,
      saleableStock,
      damagedStock,
      creditSales,
      payableToSuppliers,
      badDebts,
      totalStockValue,
      zakatPercentage, // Ensure zakatPercentage is included
      zakatPayable,
    });
  };

  const calculateTotalStockValue = (data) => {
    const {
      saleableStock,
      damagedStock,
      creditSales,
      payableToSuppliers,
      badDebts,
    } = data;
    const totalStockValue =
      parseFloat(saleableStock) +
      parseFloat(damagedStock) +
      parseFloat(creditSales) -
      parseFloat(payableToSuppliers) -
      parseFloat(badDebts);
    return isNaN(totalStockValue) ? "" : totalStockValue.toFixed(2);
  };

  const calculateZakatPayableforbusiness = (data) => {
    const totalStockValue = calculateTotalStockValue(data);
    const zakatPercentage = parseFloat(data.zakatPercentage);
    if (!isNaN(totalStockValue) && !isNaN(zakatPercentage)) {
      return (totalStockValue * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };

  //partership

  const [isAddingPartnership, setIsAddingPartnership] = useState(false);
  const [partnershipDetails, setPartnershipDetails] = useState([]);
  const [partnershipFormData, setPartnershipFormData] = useState({
    name: "",
    capitalBalance: "",
    loansAdvanced: "",
    withdrawals: "",
    accumulatedProfit: "",
    nettTotalWorth: "",
    zakatPercentage: "2.5",
    zakatPayable: "",
  });

  const handlePartnershipChange = (field, value) => {
    setPartnershipFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const savePartnership = () => {
    const newData = {
      ...partnershipFormData,
      zakatPayable: calculateZakatPayableforpartnership(partnershipFormData),
    };

    if (!isEditing) {
      setPartnershipDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...partnershipDetails];
      updatedDetails[editIndex] = newData;
      setPartnershipDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingPartnership(false);
    resetPartnershipFormData(); // Reset form data after saving
  };

  const resetPartnershipFormData = () => {
    setPartnershipFormData({
      name: "",
      capitalBalance: "",
      loansAdvanced: "",
      withdrawals: "",
      accumulatedProfit: "",
      nettTotalWorth: "",
      zakatPercentage: "2.5",
      zakatPayable: "",
    });
  };

  const deletePartnership = (index) => {
    setPartnershipDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  const openPartnershipForm = (index) => {
    setIsAddingPartnership(true);
    setIsEditing(true); // Set isEditing to true when opening for editing
    setEditIndex(index); // Set the index of the item being edited
    const {
      name,
      capitalBalance,
      loansAdvanced,
      withdrawals,
      accumulatedProfit,
      nettTotalWorth,
      zakatPercentage,
      zakatPayable,
    } = partnershipDetails[index];
    setPartnershipFormData({
      name,
      capitalBalance,
      loansAdvanced,
      withdrawals,
      accumulatedProfit,
      nettTotalWorth,
      zakatPercentage,
      zakatPayable,
    });
  };
  const calculateNettTotalWorth = (updatedValue, inputName) => {
    const { capitalBalance, loansAdvanced, accumulatedProfit, withdrawals } =
      partnershipFormData;
    let updatedNetTotalWorth =
      parseFloat(capitalBalance) +
      parseFloat(loansAdvanced) +
      parseFloat(accumulatedProfit) -
      parseFloat(withdrawals);

    // If the updated input is one of the contributing fields, adjust the value accordingly
    if (
      [
        "capitalBalance",
        "loansAdvanced",
        "accumulatedProfit",
        "withdrawals",
      ].includes(inputName)
    ) {
      updatedNetTotalWorth +=
        parseFloat(updatedValue) - parseFloat(partnershipFormData[inputName]);
    }

    return isNaN(updatedNetTotalWorth) ? "" : updatedNetTotalWorth.toFixed(2);
  };
  const calculateZakatPayableforpartnership = () => {
    const nettTotalWorth = calculateNettTotalWorth();
    const zakatPercentage = 2.5; // Fixed zakat percentage
    if (!isNaN(nettTotalWorth)) {
      return (nettTotalWorth * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };
  const calculateEstimatedValue = (weight, valuePerGram) => {
    const parsedWeight = parseFloat(weight);
    const parsedValuePerGram = parseFloat(valuePerGram);
    if (!isNaN(parsedWeight) && !isNaN(parsedValuePerGram)) {
      return (parsedWeight * parsedValuePerGram).toFixed(2);
    }
    return "";
  };

  // General liabilities state
  const [isAddingLiabilities, setIsAddingLiabilities] = useState(false);
  const [generalLiabilitiesDetails, setGeneralLiabilitiesDetails] = useState(
    []
  );
  const [liabilitiesFormData, setLiabilitiesFormData] = useState({
    loansFromFriends: "",
    loansFromCompany: "",
    rentPayable: "",
    totalLiabilities: "",
    zakatPercentage: "2.5",
    zakatPayable: "",
  });

  // Functions for managing general liabilities
  const handleLiabilitiesChange = (field, value) => {
    setLiabilitiesFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const saveLiabilities = () => {
    const totalLiabilities =
      parseFloat(liabilitiesFormData.loansFromFriends) +
      parseFloat(liabilitiesFormData.loansFromCompany) +
      parseFloat(liabilitiesFormData.rentPayable);

    const newData = {
      ...liabilitiesFormData,
      totalLiabilities: totalLiabilities.toFixed(2),
      zakatPayable: calculateZakatPayableforliabilites(totalLiabilities),
    };

    if (!isEditing) {
      setGeneralLiabilitiesDetails((prevDetails) => [...prevDetails, newData]);
    } else {
      const updatedDetails = [...generalLiabilitiesDetails];
      updatedDetails[editIndex] = newData;
      setGeneralLiabilitiesDetails(updatedDetails);
      setIsEditing(false);
    }

    setIsAddingLiabilities(false);
    resetLiabilitiesFormData(); // Reset form data after saving
  };

  const resetLiabilitiesFormData = () => {
    setLiabilitiesFormData({
      loansFromFriends: "",
      loansFromCompany: "",
      rentPayable: "",
      totalLiabilities: "",
      zakatPercentage: "2.5",
      zakatPayable: "",
    });
  };

  const deleteLiabilities = (index) => {
    setGeneralLiabilitiesDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails.splice(index, 1);
      return updatedDetails;
    });
  };

  const openLiabilitiesForm = (index) => {
    setIsAddingLiabilities(true);
    setIsEditing(true); // Set isEditing to true when opening for editing
    setEditIndex(index); // Set the index of the item being edited
    const {
      loansFromFriends,
      loansFromCompany,
      rentPayable,
      totalLiabilities,
      zakatPercentage,
      zakatPayable,
    } = generalLiabilitiesDetails[index];
    setLiabilitiesFormData({
      loansFromFriends,
      loansFromCompany,
      rentPayable,
      totalLiabilities,
      zakatPercentage,
      zakatPayable,
    });
  };

  const calculateZakatPayableforliabilites = (totalLiabilities) => {
    const zakatPercentage = 2.75; // Fixed zakat percentage
    if (!isNaN(totalLiabilities)) {
      return (totalLiabilities * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };

  const calculateZakatPayable = (estimatedValue, zakatPercentage) => {
    const parsedEstimatedValue = parseFloat(estimatedValue);
    const parsedZakatPercentage = parseFloat(zakatPercentage);
    if (!isNaN(parsedEstimatedValue) && !isNaN(parsedZakatPercentage)) {
      return (parsedEstimatedValue * (parsedZakatPercentage / 100)).toFixed(2);
    }
    return "";
  };

  return (
    <div className="zakatCalculation-main">
      <h2>Zakat Calculator</h2>
      <div className="zakatCalculation-container">
        <form className="zakatCalculationForm">
          {/* Gold section */}
          <div className="zakatCalculation-gold-section">
            <h3>Gold Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Weight</div>
              <div className="zakatCalculation-label">Value Per Gram</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {goldDetails.map((gold, index) => (
              <GoldDetail
                key={index}
                gold={gold}
                index={index}
                openGoldForm={openGoldForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingGold && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingGold(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Gold
              </button>
            )}
            {isAddingGold && (
              <GoldForm
                goldFormData={goldFormData}
                handleGoldChange={(field, value) =>
                  handleGoldChange(field, value)
                }
                saveGold={saveGold}
                closeGoldForm={() => setIsAddingGold(false)}
                isEditing={isEditing}
              />
            )}
          </div>
          {/* Silver section */}
          <div className="zakatCalculation-silver-section">
            <h3>Silver Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Weight</div>
              <div className="zakatCalculation-label">Value Per Gram</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {silverDetails.map((silver, index) => (
              <SilverDetail
                key={index}
                silver={silver}
                index={index}
                openSilverForm={openSilverForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingSilver && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingSilver(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Silver
              </button>
            )}
            {isAddingSilver && (
              <SilverForm
                silverFormData={silverFormData}
                handleSilverChange={(field, value) =>
                  handleSilverChange(field, value)
                }
                saveSilver={saveSilver}
                closeSilverForm={() => setIsAddingSilver(false)}
                isEditing={isEditing}
              />
            )}
          </div>
          {/* PreciousStones section */}
          <div className="zakatCalculation-preciousStones-section">
            <h3>PreciousStones Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {preciousStonesDetails.map((preciousStones, index) => (
              <PreciousStonesDetail
                key={index}
                preciousStones={preciousStones}
                index={index}
                openPreciousStonesForm={openPreciousStonesForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingPreciousStones && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingPreciousStones(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add PreciousStones
              </button>
            )}
            {isAddingPreciousStones && (
              <PreciousStonesForm
                preciousStonesFormData={preciousStonesFormData}
                handlePreciousStonesChange={(field, value) =>
                  setPreciousStonesFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                savePreciousStones={savePreciousStones}
                closePreciousStonesForm={() => setIsAddingPreciousStones(false)}
              />
            )}
          </div>

          {/* Cash section */}
          <div className="zakatCalculation-cash-section">
            <h3>Cash Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {cashDetails.map((cash, index) => (
              <CashDetail
                key={index}
                cash={cash}
                index={index}
                openCashForm={openCashForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingCash && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingCash(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Cash
              </button>
            )}
            {isAddingCash && (
              <CashForm
                cashFormData={cashFormData}
                handleCashChange={(field, value) =>
                  setCashFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                saveCash={saveCash}
                closeCashForm={() => setIsAddingCash(false)}
              />
            )}
          </div>

          {/* Investments section */}
          <div className="zakatCalculation-investments-section">
            <h3>Investments Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {investmentsDetails.map((investments, index) => (
              <InvestmentsDetail
                key={index}
                investments={investments}
                index={index}
                openInvestmentsForm={openInvestmentsForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingInvestments && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingInvestments(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Investments
              </button>
            )}
            {isAddingInvestments && (
              <InvestmentsForm
                investmentsFormData={investmentsFormData}
                handleInvestmentsChange={(field, value) =>
                  setInvestmentsFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                saveInvestments={saveInvestments}
                closeInvestmentsForm={() => setIsAddingInvestments(false)}
              />
            )}
          </div>

          {/* Land section */}
          <div className="zakatCalculation-land-section">
            <h3>Land Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {landDetails.map((land, index) => (
              <LandDetail
                key={index}
                land={land}
                index={index}
                openLandForm={openLandForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingLand && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingLand(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Land
              </button>
            )}
            {isAddingLand && (
              <LandForm
                landFormData={landFormData}
                handleLandChange={(field, value) =>
                  setLandFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                saveLand={saveLand}
                closeLandForm={() => setIsAddingLand(false)}
              />
            )}
          </div>

          {/* Agricultural section */}
          <div className="zakatCalculation-agricultural-section">
            <h3>Agricultural Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {agriculturalDetails.map((agricultural, index) => (
              <AgriculturalDetail
                key={index}
                agricultural={agricultural}
                index={index}
                openAgriculturalForm={openAgriculturalForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingAgricultural && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingAgricultural(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Agricultural
              </button>
            )}
            {isAddingAgricultural && (
              <AgriculturalForm
                agriculturalFormData={agriculturalFormData}
                handleAgriculturalChange={(field, value) =>
                  setAgriculturalFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                saveAgricultural={saveAgricultural}
                closeAgriculturalForm={() => setIsAddingAgricultural(false)}
              />
            )}
          </div>

          {/* AnimalandPoultry section */}
          <div className="zakatCalculation-animalandPoultry-section">
            <h3>AnimalandPoultry Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Type</div>
              <div className="zakatCalculation-label">Estimated Value</div>
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {animalandPoultryDetails.map((animalandPoultry, index) => (
              <AnimalandPoultryDetail
                key={index}
                animalandPoultry={animalandPoultry}
                index={index}
                openAnimalandPoultryForm={openAnimalandPoultryForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingAnimalandPoultry && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingAnimalandPoultry(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add AnimalandPoultry
              </button>
            )}
            {isAddingAnimalandPoultry && (
              <AnimalandPoultryForm
                animalandPoultryFormData={animalandPoultryFormData}
                handleAnimalandPoultryChange={(field, value) =>
                  setAnimalandPoultryFormData((prevData) => ({
                    ...prevData,
                    [field]: value,
                  }))
                }
                saveAnimalandPoultry={saveAnimalandPoultry}
                closeAnimalandPoultryForm={() =>
                  setIsAddingAnimalandPoultry(false)
                }
              />
            )}
          </div>
          {/* Business section */}
          <div className="zakatCalculation-business-section">
            <h3>Business Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Name</div>
              <div className="zakatCalculation-label">
                Saleable Stock
              </div>
              <div className="zakatCalculation-label">
                Damaged Stock
              </div>
              <div className="zakatCalculation-label">
                Receivable from Creditor
              </div>
              <div className="zakatCalculation-label">
                Payable to Suppliers
              </div>
              <div className="zakatCalculation-label">Bad Debts</div>
              <div className="zakatCalculation-label">Total Value of Stock</div>
            
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {businessDetails.map((business, index) => (
              <BusinessDetail
                key={index}
                business={business}
                index={index}
                openBusinessForm={openBusinessForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingBusiness && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingBusiness(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Business
              </button>
            )}
            {isAddingBusiness && (
              <BusinessForm
                businessFormData={businessFormData}
                handleBusinessChange={(field, value) =>
                  handleBusinessChange(field, value)
                }
                saveBusiness={saveBusiness}
                closeBusinessForm={() => setIsAddingBusiness(false)}
              />
            )}
          </div>

          {/* Partnership section */}
          <div className="zakatCalculation-partnership-section">
            <h3>Partnership Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">Name</div>
              <div className="zakatCalculation-label">Capital Balance</div>
              <div className="zakatCalculation-label">Loans Advanced</div>
              <div className="zakatCalculation-label">Withdrawals</div>
              <div className="zakatCalculation-label">Accumulated Profit</div>
              <div className="zakatCalculation-label">Net Worth</div>
            
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {partnershipDetails.map((partnership, index) => (
              <PartnershipDetail
                key={index}
                partnership={partnership}
                index={index}
                openPartnershipForm={openPartnershipForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingPartnership && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingPartnership(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add Partnership
              </button>
            )}
            {isAddingPartnership && (
              <PartnershipForm
                partnershipFormData={partnershipFormData}
                handlePartnershipChange={(field, value) =>
                  handlePartnershipChange(field, value)
                }
                savePartnership={savePartnership}
                closePartnershipForm={() => setIsAddingPartnership(false)}
              />
            )}
          </div>

          {/* General Liabilities section */}
          <div className="zakatCalculation-general-liabilities-section">
            <h3>General Liabilities Details</h3>
            <div className="zakatCalculation-row itemheading">
              <div className="zakatCalculation-label">
                Loans of Friends,Cards
              </div>
              <div className="zakatCalculation-label">Loans from Company</div>
              <div className="zakatCalculation-label">Rent Payable</div>
              <div className="zakatCalculation-label">Total Liabilities</div>
            
              <div className="zakatCalculation-label">Zakat Payable</div>
              <div className="zakatCalculation-label">Actions</div>
            </div>
            {generalLiabilitiesDetails.map((liability, index) => (
              <GeneralLiabilitiesDetail
                key={index}
                liabilities={liability}
                index={index}
                openLiabilitiesForm={openLiabilitiesForm}
                openDeleteConfirmation={openDeleteConfirmation}
              />
            ))}
            {!isAddingLiabilities && (
              <button
                type="button"
                className="add-data-button"
                onClick={() => setIsAddingLiabilities(true)}
              >
                <MdAddCircleOutline className="add-icon" />
                Add General Liabilities
              </button>
            )}
            {isAddingLiabilities && (
              <GeneralLiabilitiesForm
                liabilitiesFormData={liabilitiesFormData}
                handleLiabilitiesChange={(field, value) =>
                  handleLiabilitiesChange(field, value)
                }
                saveLiabilities={saveLiabilities}
                closeLiabilitiesForm={() => setIsAddingLiabilities(false)}
              />
            )}
          </div>
        </form>
      </div>

      {!showFinalInfo ? (
        <button
          type="button"
          className="submit-button"
          onClick={handleShowReport}
        >
          Submit Zakat Calculation
        </button>
      ) : (
        <FinalInfo onSubmit={handleSubmitFinalInfo} />
      )}
      {queryString && (
        <button
          className="submit-button"
          onClick={() => (window.location.href = `/report${queryString}`)}
        >
          Proceed to Report
        </button>
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          message={`Are you sure you want to delete this ${deleteType}?`}
          onCancel={closeDeleteConfirmation}
          onConfirm={confirmDelete}
        />
      )}
    </div>
  );
};

export default ZakatCalculation;
