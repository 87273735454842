import React, { useState, useEffect } from "react";

const BusinessForm = ({
  businessFormData,
  handleBusinessChange,
  saveBusiness,
  closeBusinessForm,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    saleableStock: "",
    damagedStock: "",
    creditSales: "",
    payableToSuppliers: "",
    badDebts: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      !validationErrors.name &&
        !validationErrors.saleableStock &&
        !validationErrors.damagedStock &&
        !validationErrors.creditSales &&
        !validationErrors.payableToSuppliers &&
        !validationErrors.badDebts &&
        !!businessFormData.name &&
        !!businessFormData.saleableStock &&
        !!businessFormData.damagedStock &&
        !!businessFormData.creditSales &&
        !!businessFormData.payableToSuppliers &&
        !!businessFormData.badDebts
    );
  }, [validationErrors, businessFormData]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) {
          error = "This field is required";
        }
        break;
      case "saleableStock":
      case "damagedStock":
      case "creditSales":
      case "payableToSuppliers":
      case "badDebts":
        if (!value) {
          error = "This field is required";
        } else if (isNaN(value) || parseFloat(value) < 0) {
          error = "Value must be a positive number";
        }
        break;
      default:
        break;
    }
    setValidationErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleBusinessChange(name, value);
    validateInput(name, value);
  };

  const calculateTotalStockValue = () => {
    const {
      saleableStock,
      damagedStock,
      creditSales,
      payableToSuppliers,
      badDebts,
    } = businessFormData;
    return (
      parseFloat(saleableStock) +
      parseFloat(damagedStock) +
      parseFloat(creditSales) -
      parseFloat(payableToSuppliers) -
      parseFloat(badDebts)
    ).toFixed(2);
  };

  const calculateZakatPayable = () => {
    const totalStockValue = calculateTotalStockValue();
    const zakatPercentage = 2.5; // Fixed zakat percentage
    if (!isNaN(totalStockValue)) {
      return (totalStockValue * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };
  return (
    <div className="addnewitemform-overlay">
      <div className="addnewitemform-container">
        <h2>Add Business</h2>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={businessFormData.name}
                onChange={handleInputChange}
                className={validationErrors.name ? "invalid" : ""}
              />
              {validationErrors.name && (
                <p className="error-message">{validationErrors.name}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="saleableStock">Saleable Stock:</label>
              <input
                type="text"
                id="saleableStock"
                name="saleableStock"
                value={businessFormData.saleableStock}
                onChange={handleInputChange}
                className={validationErrors.saleableStock ? "invalid" : ""}
              />
              {validationErrors.saleableStock && (
                <p className="error-message">
                  {validationErrors.saleableStock}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="damagedStock">Damaged Stock:</label>
              <input
                type="text"
                id="damagedStock"
                name="damagedStock"
                value={businessFormData.damagedStock}
                onChange={handleInputChange}
                className={validationErrors.damagedStock ? "invalid" : ""}
              />
              {validationErrors.damagedStock && (
                <p className="error-message">{validationErrors.damagedStock}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="creditSales">Credit Sales:</label>
              <input
                type="text"
                id="creditSales"
                name="creditSales"
                value={businessFormData.creditSales}
                onChange={handleInputChange}
                className={validationErrors.creditSales ? "invalid" : ""}
              />
              {validationErrors.creditSales && (
                <p className="error-message">{validationErrors.creditSales}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="payableToSuppliers">Payable to Suppliers:</label>
              <input
                type="text"
                id="payableToSuppliers"
                name="payableToSuppliers"
                value={businessFormData.payableToSuppliers}
                onChange={handleInputChange}
                className={validationErrors.payableToSuppliers ? "invalid" : ""}
              />
              {validationErrors.payableToSuppliers && (
                <p className="error-message">
                  {validationErrors.payableToSuppliers}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="badDebts">Bad Debts:</label>
              <input
                type="text"
                id="badDebts"
                name="badDebts"
                value={businessFormData.badDebts}
                onChange={handleInputChange}
                className={validationErrors.badDebts ? "invalid" : ""}
              />
              {validationErrors.badDebts && (
                <p className="error-message">{validationErrors.badDebts}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="totalStockValue">TOTAL VALUE OF STOCK:</label>
              <input
                type="text"
                id="totalStockValue"
                name="totalStockValue"
                value={calculateTotalStockValue()}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="zakatPercentage">Zakat Percentage:</label>
              <input
                type="text"
                id="zakatPercentage"
                name="zakatPercentage"
                value="2.5%"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="zakatPayable">Zakat Payable:</label>
              <input
                type="text"
                id="zakatPayable"
                name="zakatPayable"
                value={calculateZakatPayable()}
                readOnly
              />
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={saveBusiness}
              disabled={!isFormValid}
              className="additem-button"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closeBusinessForm}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
          <div className="user-notes">
            <p className="guideline-heading">Guidelines:</p>
            <ul>
              <li className="guide-label">
                <span className="label-bold">Name:</span> Enter the name of the
                business.
              </li>
              <li className="guide-label">
                <span className="label-bold">Saleable Stock:</span> Enter the
                value of stock that is in good condition and available for sale.
              </li>
              <li className="guide-label">
                <span className="label-bold">Damaged Stock:</span> Enter the
                value of stock that is damaged or unsellable.
              </li>
              <li className="guide-label">
                <span className="label-bold">Credit Sales:</span> Enter the
                value of sales made on credit to customers.
              </li>
              <li className="guide-label">
                <span className="label-bold">Payable to Suppliers:</span> Enter
                the amount owed to suppliers for goods or services received.
              </li>
              <li className="guide-label">
                <span className="label-bold">Bad Debts:</span> Enter the amount
                of money that is unlikely to be recovered from customers who owe
                money.
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessForm;
