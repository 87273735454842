import React, { useRef, useState, useEffect, useCallback } from "react";
import "./Report.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Trustlogo from "../../../../trustlogo.png";
import Mrglogo from "../../../../mrglogo.png";
import ZakatEligible from "./ZakatEligible/ZakatEligible";
const Report = ({
  userName,
  nisabValue,
  currentGoldValue,
  partnershipDetails,
  goldDetails,
  silverDetails,
  businessDetails,
  preciousStonesDetails,
  cashDetails,
  landDetails,
  investmentsDetails,
  agriculturalDetails,
  animalandPoultryDetails,
  generalLiabilitiesDetails,
}) => {
  const reportRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleDownloadImage = async () => {
    try {
      const reportContainer = reportRef.current;

      // Scroll to the top of the page
      window.scrollTo(0, 0);

      // Wait for scrolling to complete
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Capture the report container as an image using html2canvas
      const canvas = await html2canvas(reportContainer, {
        scrollY: -window.scrollY,
        useCORS: true,
        logging: true,
      });

      // Convert the canvas to a Data URL representing a PNG image
      const imgData = canvas.toDataURL("image/png");

      // Determine the size of the PDF based on the size of the report container
      const reportWidth = reportContainer.offsetWidth;
      const reportHeight = reportContainer.offsetHeight;

      // Create a new PDF document with dynamic dimensions
      const pdf = new jsPDF({
        orientation: reportWidth > reportHeight ? "l" : "p", // Landscape or portrait based on container dimensions
        unit: "mm",
        format: [reportWidth, reportHeight], // Use container dimensions for PDF
      });

      // Add the captured image to the PDF document
      pdf.addImage(imgData, "PNG", 0, 0, reportWidth, reportHeight);

      // Save the PDF document with a specified filename
      pdf.save("zakat_report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      // Optionally handle errors, e.g., display a toast notification
    }
  };

  const getCurrentYear = () => new Date().getFullYear();

  const calculateTotalZakatPayable = useCallback(() => {
    let totalZakat = 0;

    // Iterate over partnershipDetails and sum zakatPayable
    if (partnershipDetails) {
      partnershipDetails.forEach((partner) => {
        console.log("Partner zakatPayable:", partner.zakatPayable);
        totalZakat += parseFloat(partner.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over goldDetails and sum zakatPayable
    if (goldDetails) {
      goldDetails.forEach((gold) => {
        console.log("Gold zakatPayable:", gold.zakatPayable);
        totalZakat += parseFloat(gold.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over silverDetails and sum zakatPayable
    if (silverDetails) {
      silverDetails.forEach((silver) => {
        console.log("Silver zakatPayable:", silver.zakatPayable);
        totalZakat += parseFloat(silver.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over businessDetails and sum zakatPayable
    if (businessDetails) {
      businessDetails.forEach((business) => {
        console.log("Business zakatPayable:", business.zakatPayable);
        totalZakat += parseFloat(business.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over preciousStonesDetails and sum zakatPayable
    if (preciousStonesDetails) {
      preciousStonesDetails.forEach((preciousStone) => {
        console.log("Precious Stone zakatPayable:", preciousStone.zakatPayable);
        totalZakat += parseFloat(preciousStone.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over cashDetails and sum zakatPayable
    if (cashDetails) {
      cashDetails.forEach((cash) => {
        console.log("Cash zakatPayable:", cash.zakatPayable);
        totalZakat += parseFloat(cash.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over landDetails and sum zakatPayable
    if (landDetails) {
      landDetails.forEach((land) => {
        console.log("Land zakatPayable:", land.zakatPayable);
        totalZakat += parseFloat(land.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over investmentsDetails and sum zakatPayable
    if (investmentsDetails) {
      investmentsDetails.forEach((investment) => {
        console.log("Investment zakatPayable:", investment.zakatPayable);
        totalZakat += parseFloat(investment.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over agriculturalDetails and sum zakatPayable
    if (agriculturalDetails) {
      agriculturalDetails.forEach((agricultural) => {
        console.log("Agricultural zakatPayable:", agricultural.zakatPayable);
        totalZakat += parseFloat(agricultural.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Iterate over animalandPoultryDetails and sum zakatPayable
    if (animalandPoultryDetails) {
      animalandPoultryDetails.forEach((animal) => {
        console.log("Animal and Poultry zakatPayable:", animal.zakatPayable);
        totalZakat += parseFloat(animal.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    // Subtract the zakat payable of generalLiabilitiesDetails
    if (generalLiabilitiesDetails) {
      generalLiabilitiesDetails.forEach((liability) => {
        console.log("Liability zakatPayable:", liability.zakatPayable);
        totalZakat -= parseFloat(liability.zakatPayable);
        console.log("Current Total Zakat:", totalZakat);
      });
    }

    console.log("Total Zakat:", totalZakat);
    const roundedTotalZakat = Math.round(totalZakat * 100) / 100; // Round to 2 decimal places

    return { totalZakat, roundedTotalZakat };
  }, [
    partnershipDetails,
    goldDetails,
    silverDetails,
    businessDetails,
    preciousStonesDetails,
    cashDetails,
    landDetails,
    investmentsDetails,
    agriculturalDetails,
    animalandPoultryDetails,
    generalLiabilitiesDetails,
  ]);

  const calculateTotalEstimatedValue = useCallback(() => {
    let totalEstimatedValue = 0;

    // Iterate over partnershipDetails and sum estimated value
    if (partnershipDetails) {
      partnershipDetails.forEach((partner) => {
        totalEstimatedValue += parseFloat(partner.estimatedValue);
      });
    }

    // Iterate over goldDetails and sum estimated value
    if (goldDetails) {
      goldDetails.forEach((gold) => {
        totalEstimatedValue += parseFloat(gold.estimatedValue);
      });
    }

    // Iterate over silverDetails and sum estimated value
    if (silverDetails) {
      silverDetails.forEach((silver) => {
        totalEstimatedValue += parseFloat(silver.estimatedValue);
      });
    }

    // Iterate over businessDetails and sum estimated value
    if (businessDetails) {
      businessDetails.forEach((business) => {
        totalEstimatedValue += parseFloat(business.estimatedValue);
      });
    }

    // Iterate over preciousStonesDetails and sum estimated value
    if (preciousStonesDetails) {
      preciousStonesDetails.forEach((preciousStone) => {
        totalEstimatedValue += parseFloat(preciousStone.estimatedValue);
      });
    }

    // Iterate over cashDetails and sum estimated value
    if (cashDetails) {
      cashDetails.forEach((cash) => {
        totalEstimatedValue += parseFloat(cash.estimatedValue);
      });
    }

    // Iterate over landDetails and sum estimated value
    if (landDetails) {
      landDetails.forEach((land) => {
        totalEstimatedValue += parseFloat(land.estimatedValue);
      });
    }

    // Iterate over investmentsDetails and sum estimated value
    if (investmentsDetails) {
      investmentsDetails.forEach((investment) => {
        totalEstimatedValue += parseFloat(investment.estimatedValue);
      });
    }

    // Iterate over agriculturalDetails and sum estimated value
    if (agriculturalDetails) {
      agriculturalDetails.forEach((agricultural) => {
        totalEstimatedValue += parseFloat(agricultural.estimatedValue);
      });
    }

    // Iterate over animalandPoultryDetails and sum estimated value
    if (animalandPoultryDetails) {
      animalandPoultryDetails.forEach((animal) => {
        totalEstimatedValue += parseFloat(animal.estimatedValue);
      });
    }

    console.log("Total Estimated Value:", totalEstimatedValue);
    return totalEstimatedValue;
  }, [
    partnershipDetails,
    goldDetails,
    silverDetails,
    businessDetails,
    preciousStonesDetails,
    cashDetails,
    landDetails,
    investmentsDetails,
    agriculturalDetails,
    animalandPoultryDetails,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const totalEstimate = calculateTotalEstimatedValue();
      if (totalEstimate < nisabValue) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [nisabValue, calculateTotalEstimatedValue]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="report-main" ref={reportRef}>
      <div className="report-container">
        <div className="report-header-container">
          <img src={Trustlogo} alt="Trust Logo" className="report-trust-logo" />
          <p className="report-username">
            {userName}
            <span>'s</span>
          </p>
          <img
            src={Mrglogo}
            alt="Company Logo"
            className="report-company-logo"
          />
        </div>

        <h2 className="report-title">Zakat {getCurrentYear()} Report</h2>

        {partnershipDetails.length > 0 && (
          <div className="section">
            <h3>Partnership Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Name</div>
              <div className="detail-heading">Capital Balance</div>
              <div className="detail-heading">Loans Advanced</div>
              <div className="detail-heading">Withdrawals</div>
              <div className="detail-heading">Accumulated Profit</div>
              <div className="detail-heading">Nett Total Worth</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {partnershipDetails.map((partner, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{partner.name}</div>
                <div className="detail-value">{partner.capitalBalance}</div>
                <div className="detail-value">{partner.loansAdvanced}</div>
                <div className="detail-value">{partner.withdrawals}</div>
                <div className="detail-value">{partner.accumulatedProfit}</div>
                <div className="detail-value">{partner.nettTotalWorth}</div>
                <div className="detail-value">{partner.zakatPercentage}</div>
                <div className="detail-value">{partner.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {goldDetails.length > 0 && (
          <div className="section">
            <h3>Gold Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Weight</div>
              <div className="detail-heading">Value Per Gram</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {goldDetails.map((gold, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{gold.type}</div>
                <div className="detail-value">{gold.weight}</div>
                <div className="detail-value">{gold.valuePerGram}</div>
                <div className="detail-value">{gold.estimatedValue}</div>
                <div className="detail-value">{gold.zakatPercentage}</div>
                <div className="detail-value">{gold.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {silverDetails.length > 0 && (
          <div className="section">
            <h3>Silver Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Weight</div>
              <div className="detail-heading">Value Per Gram</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {silverDetails.map((silver, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{silver.type}</div>
                <div className="detail-value">{silver.weight}</div>
                <div className="detail-value">{silver.valuePerGram}</div>
                <div className="detail-value">{silver.estimatedValue}</div>
                <div className="detail-value">{silver.zakatPercentage}</div>
                <div className="detail-value">{silver.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {preciousStonesDetails.length > 0 && (
          <div className="section">
            <h3>Precious Stones Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {preciousStonesDetails.map((preciousStones, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{preciousStones.type}</div>
                <div className="detail-value">
                  {preciousStones.estimatedValue}
                </div>
                <div className="detail-value">
                  {preciousStones.zakatPercentage}
                </div>
                <div className="detail-value">
                  {preciousStones.zakatPayable}
                </div>
              </div>
            ))}
          </div>
        )}
        {cashDetails.length > 0 && (
          <div className="section">
            <h3>Cash Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {cashDetails.map((cash, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{cash.type}</div>
                <div className="detail-value">{cash.estimatedValue}</div>
                <div className="detail-value">{cash.zakatPercentage}</div>
                <div className="detail-value">{cash.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {landDetails.length > 0 && (
          <div className="section">
            <h3>Land Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {landDetails.map((land, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{land.type}</div>
                <div className="detail-value">{land.estimatedValue}</div>
                <div className="detail-value">{land.zakatPercentage}</div>
                <div className="detail-value">{land.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {investmentsDetails.length > 0 && (
          <div className="section">
            <h3>Investments Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {investmentsDetails.map((investment, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{investment.type}</div>
                <div className="detail-value">{investment.estimatedValue}</div>
                <div className="detail-value">{investment.zakatPercentage}</div>
                <div className="detail-value">{investment.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {agriculturalDetails.length > 0 && (
          <div className="section">
            <h3>Agricultural Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {agriculturalDetails.map((agricultural, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{agricultural.type}</div>
                <div className="detail-value">
                  {agricultural.estimatedValue}
                </div>
                <div className="detail-value">
                  {agricultural.zakatPercentage}
                </div>
                <div className="detail-value">{agricultural.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {businessDetails.length > 0 && (
          <div className="section">
            <h3>Business Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Name</div>
              <div className="detail-heading">Saleable Stock</div>
              <div className="detail-heading">Damaged Stock</div>
              <div className="detail-heading">Credit Sales</div>
              <div className="detail-heading">Payable to Suppliers</div>
              <div className="detail-heading">Bad Debts</div>
              <div className="detail-heading">Total Stock Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {businessDetails.map((business, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{business.name}</div>
                <div className="detail-value">{business.saleableStock}</div>
                <div className="detail-value">{business.damagedStock}</div>
                <div className="detail-value">{business.creditSales}</div>
                <div className="detail-value">
                  {business.payableToSuppliers}
                </div>
                <div className="detail-value">{business.badDebts}</div>
                <div className="detail-value">{business.totalStockValue}</div>
                <div className="detail-value">{business.zakatPercentage}</div>
                <div className="detail-value">{business.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        {animalandPoultryDetails.length > 0 && (
          <div className="section">
            <h3>Animal and Poultry Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Type</div>
              <div className="detail-heading">Estimated Value</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {animalandPoultryDetails.map((animalandPoultry, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{animalandPoultry.type}</div>
                <div className="detail-value">
                  {animalandPoultry.estimatedValue}
                </div>
                <div className="detail-value">
                  {animalandPoultry.zakatPercentage}
                </div>
                <div className="detail-value">
                  {animalandPoultry.zakatPayable}
                </div>
              </div>
            ))}
          </div>
        )}
        {generalLiabilitiesDetails.length > 0 && (
          <div className="section">
            <h3>Liabilities Details</h3>
            <div className="heading-row">
              <div className="detail-heading">Loans From Friends</div>
              <div className="detail-heading">Loans From Company</div>
              <div className="detail-heading">Rent Payable</div>
              <div className="detail-heading">Total Liabilities</div>
              <div className="detail-heading">Zakat Percentage</div>
              <div className="detail-heading">Zakat Payable</div>
            </div>
            {generalLiabilitiesDetails.map((liability, index) => (
              <div className="details-row" key={index}>
                <div className="detail-value">{liability.loansFromFriends}</div>
                <div className="detail-value">{liability.loansFromCompany}</div>
                <div className="detail-value">{liability.rentPayable}</div>
                <div className="detail-value">{liability.totalLiabilities}</div>
                <div className="detail-value">{liability.zakatPercentage}</div>
                <div className="detail-value">{liability.zakatPayable}</div>
              </div>
            ))}
          </div>
        )}
        <div className="final-zakatvalue-container">
          {nisabValue < calculateTotalEstimatedValue() ? (
            <div className="finalzakatvalue-top">
              <div className="final-zakatvaluedetail-value">
                Current Gold Value: {currentGoldValue}
              </div>
              <div className="final-zakatvaluedetail-value">
                Nisab Value: {nisabValue}
              </div>
              <div className="final-zakatvaluedetail-value">
                Total Value:{ calculateTotalEstimatedValue() }
              </div>
              <div className="final-zakatvaluedetail-value">
                Zakat Value: {calculateTotalZakatPayable().roundedTotalZakat}
              </div>
            </div>
          ) : (
            <div className="finalzakatvalue-top">
              <div className="final-zakatvaluedetail-value">
              According to Islamic guidelines, Zakat is not compulsory for this year now. You are not eligible for Zakat as your financial estimate value is {calculateTotalEstimatedValue()}, which falls below the Nisab threshold of {nisabValue}. 
  Zakat becomes obligatory only when your estimate value exceeds the Nisab threshold. Therefore, to be eligible for Zakat, your estimate value should be greater than {nisabValue}.
              </div>
            </div>
          )}
          <div className="totalzakat-value-container">
            <h3>Total Zakat Payable:</h3>
            {nisabValue < calculateTotalEstimatedValue() ? (
              <div className="totalzakat-value">
                {calculateTotalZakatPayable().roundedTotalZakat}
              </div>
            ) : (
              <div className="totalzakat-value">
                Not Eligible 
              </div>
            )}
          </div>
        </div>

        <button className="download-button" onClick={handleDownloadImage}>
          Download as PDF
        </button>
        <div className="report-footer">
          <span>
            This report generated by{" "}
            <a
              href="https://aupyctrust.com"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              https://aupyctrust.com
            </a>{" "}
            || This tool designed by{" "}
            <a
              href="https://mrgengg.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              https://mrgengg.com
            </a>
            .
          </span>
        </div>
      </div>
      {showPopup && <ZakatEligible onClose={handleClosePopup} />}
    </div>
  );
};

export default Report;
