// GeneralLiabilitiesDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const GeneralLiabilitiesDetail = ({
  liabilities,
  index,
  openLiabilitiesForm,
  openDeleteConfirmation,
}) => {
  return (
    <div key={index} className="zakatCalculation-row entry-separation-line">
      <div className="zakatCalculation-value">{liabilities.loansFromFriends}</div>
      <div className="zakatCalculation-value">{liabilities.loansFromCompany}</div>
      <div className="zakatCalculation-value">{liabilities.rentPayable}</div>
      <div className="zakatCalculation-value">{liabilities.totalLiabilities}</div>
      
      <div className="zakatCalculation-value">{liabilities.zakatPayable}</div>
      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openLiabilitiesForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "liabilities")}
        />
      </div>
    </div>
  );
};

export default GeneralLiabilitiesDetail;
