// PreciousStonesDetail.js 

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const PreciousStonesDetail = ({ preciousStones, index, openPreciousStonesForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{preciousStones.type}</div>
    
      <div className="zakatCalculation-value">{preciousStones.estimatedValue}</div>
      <div className="zakatCalculation-value">{preciousStones.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openPreciousStonesForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "preciousStones")}
        />
      </div>
    </div>
  );
};

export default PreciousStonesDetail;
