// AnimalandPoultryDetail.js 

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const AnimalandPoultryDetail = ({ animalandPoultry, index, openAnimalandPoultryForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{animalandPoultry.type}</div>
    
      <div className="zakatCalculation-value">{animalandPoultry.estimatedValue}</div>
      <div className="zakatCalculation-value">{animalandPoultry.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openAnimalandPoultryForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "animalandPoultry")}
        />
      </div>
    </div>
  );
};

export default AnimalandPoultryDetail;
