// CashDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const CashDetail = ({ cash, index, openCashForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{cash.type}</div>
    
      <div className="zakatCalculation-value">{cash.estimatedValue}</div>
      <div className="zakatCalculation-value">{cash.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openCashForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "cash")}
        />
      </div>
    </div>
  );
};

export default CashDetail;
