// AgriculturalDetail.js 

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const AgriculturalDetail = ({ agricultural, index, openAgriculturalForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{agricultural.type}</div>
    
      <div className="zakatCalculation-value">{agricultural.estimatedValue}</div>
      <div className="zakatCalculation-value">{agricultural.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openAgriculturalForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "agricultural")}
        />
      </div>
    </div>
  );
};

export default AgriculturalDetail;
