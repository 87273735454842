const ZakatInstructionData = {
  intro: {
    title: "Dear Brothers & Sisters,",
    content: `Alhamdulillah, the month of Ramadhan has been bestowed upon us by Allah. ZAKAT is one of the five fundamental pillars of Islam, mandatory on all muslims who are of eligible wealth. Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan), compute your total wealth on that date and calculate Zakat, thereon. The attached spreadsheet is a humble attempt at making the calculation process simple and consolidated, for all brothers and sisters who are fortunate to be worthy of paying Zakat. If there are any errors, its purely due to my incomprehension and may be brought to my notice immdtly by email at mrgengulf@gmail.com. Please remember me in your supplications and may Allah give us all the rewards of both worlds. Aameen.`,
  },
  details: [
    {
      title: "Zakat on Pure Gold and Gold Jewellery",
      content: [
        "Zakat should be calculated at 2.5% of the market value as on the date of valuation (In our case we consider 1st of Ramadhan). Most Ulema favour the Market Value prevailing as on the date of Calculation and not the purchase price.",
        "A Reduction of 2% from the weight of Jewellery can be allowed towards studded stones jewellery & 25% from the weight of KUNDAN Jewellery.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Precious and Semi-Precious Stones",
      content: [
        "There is some contention on whether these are to be considered for valuation. In my humble opinion if they have a value, then they calculate towards your wealth, and it is on the wealth that Zakat is mandatory.",
        "One may calculate the saleable value of Items at hand on the date of Zakat Calculation.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Silver",
      content: [
        "Zakat is to be paid on Silver in Pure form or Jewellery, Utensils, Decorative items and all household items including crockery, cutlery made of silver at 2.5% of the prevailing market rates.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Cash and Bank Balances",
      content: [
        "Zakat should be paid at 2.5% on all cash balance and bank balances in your savings, current or FD accounts. The amount technically should be in the bank for one year.  Usually it happens that the balance keeps on changing as per personal requirements.",
        "You may make your best judgement and the best way is to pay on remaining amount on the day of calculation.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Loans Given, Funds, etc",
      content: [
        "Zakat is payable by you on loans you have given to your friends and relatives.  It should be treated as Cash in Hand.  You may deduct Loans Payable by you to arrive at the nett present value of your wealth.",
        "Zakat is payable on all Govt Bonds, Public Sector Bond, paid-up Insurance premiums, your paid-up portion of Provident Funds, Govt Bills receivables, etc.  ",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Landed Property",
      content: [
        "Zakat is not payable on personal residential House even if you have more than one and meant for residential purpose only.  Also Zakat is not applicable on Property given on rent irrespective of how many. However Zakat is payable on the rental income.",
        "However if your intention of holding properties is to sell at a future date for a profit or as an investment, then Zakat is payable on the Market Value.  Also, if your intention of holding properties changes in the current year, I.e. from self use to business then you need to pay Zakat on that Property Value",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Business",
      content: [
        "This is for Business Persons only. No matter what business you are into, you've got to pay Zakat on all STOCK-IN-TRADE.  The stock must be valued at its Landed Cost Price. If you have any  bills receivable (sales given on credit) then you need to add the same towards calculations.",
        "Deduct the Amounts due to your suppliers and deduct the loans on stock on the date of calculation.  Dead Stock should be calculated on scrap value or its saleable value.  Damaged stock should also be valued at its scrap value.  ",
        "Be honest in the calculations, as ZAKAT is an INSURANCE on your STOCK directly from ALLAH and who better an insurer than Him.",
        "There is no Zakat on Factory Buildings or any kind of machinery, but there is zakat on products produced in the factory (i.e. finished goods value).  Please refer to a competent Moulvi or a scholar who can shed more light on your specific issues.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Partnership Firms.",
      content: [
        "Zakat can be paid EITHER by the firm OR separately by the owners.  If the firm is not paying, and the partner wants to calculate his share, he should take the amount standing to his capital and loan account as per the last balance sheet. Add his estimated share of profit till the date zakat is calculated.",
        "This can only be estimated as it is difficult to calculate the exact profit or loss between an accounting year.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
    {
      title: "Zakat on Agricultural Product",
      content: [
        "Zakat is payable on all Agricultural produce including fruits, commercially grown flowers, vegetables and all types of grains at the harvest time itself.  The passing of One year does not apply for agrultural produce.  If there are two or more crops on the same land per year, then Zakat has to be paid as many times on the crop, irrespective of the time.",
        "On crops dependent purely on rain water it will be 10% of produce,   On crops not irrigated through rain water but use Canal Water, Tank Water, Borewell and Open wells, the Zakat is 5% of the produce.  For Crops dependent partly on Rain Water and partly on other water, the Zakat applicable would be 7.5% of produce.    ",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },

    {
      title: "Zakat on Animals",
      content: [
        "On all grazing animals like goats, shee, camel, cows, broiler chickens, the consensus Zakat payable is one animal/bird for every 40 animals owned. However you may wish to give cash in lieu of the animal/bird itself.",
        "Please consult your local Scholar or Maulvi or Imaam who can guide you to the right direction, or refer to books of Fiqh if you would like to have first hand confirmation of the situation.",
        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },

    {
      title: "Liabilities Deductions",
      content: [
        " If you have any pending tax payable to the govt, as of the date of Zakat Calculation, then the same may be deducted before arriving at the net worth.  If you have taken any loans from any person or institution, and if you have not already deducted the same from any of the above sections, then you can deduct your Payables over here.  Please be truthful, as Zakat is a sure way of protecting ones wealth if Zakat has been paid on it regularly and fully.",

        "Zakat is due from and payable by a person on his wealth (and not his income), which has remained with him/her for one Islamic year. It is difficult to calculate the completion of one year on each item of wealth, because purchase dates may vary. To overcome this difficulty, a practical method is to fix a date (e.g. 1st of Ramadhan)",
      ],
    },
  ],
};

export default ZakatInstructionData;
