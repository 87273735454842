import React, { useState, useEffect } from "react";
import "./FinalInfo.css"; // Import your CSS file for styling

const FinalInfo = ({ onSubmit }) => {
  const [userName, setUserName] = useState("");
  const [currentGoldValue, setCurrentGoldValue] = useState("");
  const [nisabValue, setNisabValue] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  useEffect(() => {
    if (currentGoldValue !== "" && userName !== "") {
      // Include userName in the dependency array
      const nisab = parseFloat(currentGoldValue) * 85;
      setNisabValue(nisab.toFixed(2));
      validateInput(userName, currentGoldValue, nisab.toFixed(2));
    }
  }, [currentGoldValue, userName]); // Add userName to the dependency array

  const validateInput = (userName, goldValue, nisabValue) => {
    if (!userName.trim()) {
      setErrorMessage("Name is required");
      setIsNextDisabled(true);
    } else if (!goldValue.trim()) {
      setErrorMessage("Gold value is required");
      setIsNextDisabled(true);
    } else if (isNaN(goldValue.trim())) {
      setErrorMessage(
        "Invalid input for gold value. Please enter a valid number"
      );
      setIsNextDisabled(true);
    } else if (isNaN(nisabValue)) {
      setErrorMessage("Error calculating Nisab value");
      setIsNextDisabled(true);
    } else {
      setErrorMessage("");
      setIsNextDisabled(false);
    }
  };

  const handleSubmit = () => {
    if (!isNextDisabled) {
      onSubmit(userName, currentGoldValue, nisabValue);
      setShowModal(false);
    }
  };

  return (
    <>
      {showModal && (
        <div className="finalinfo-main">
          <div className="finalinfo-content">
            <h2>Enter Your Information</h2>
            <div className="label-container">
              <label htmlFor="userName">User Name:</label>
            </div>
            <input
              type="text"
              id="userName"
              className="input-text"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
                validateInput(e.target.value, currentGoldValue, nisabValue);
              }}
            />
            <div className="label-container">
              <label htmlFor="currentGoldValue">Current Gold Value:</label>
            </div>
            <input
              type="text"
              id="currentGoldValue"
              className="input-text"
              value={currentGoldValue}
              onChange={(e) => {
                setCurrentGoldValue(e.target.value);
                validateInput(userName, e.target.value, nisabValue);
              }}
            />
            <div className="label-container">
              <label htmlFor="nisabValue">Nisab Value:</label>
            </div>
            <input
              type="text"
              id="nisabValue"
              className="input-text"
              value={nisabValue}
              disabled
            />
            {errorMessage && (
              <p className="finalinfor-error-message">{errorMessage}</p>
            )}
            <button
              className="finalinfo-button"
              onClick={handleSubmit}
              disabled={isNextDisabled}
            >
              Next
            </button>
            <div className="user-notes">
              <p className="guideline-heading">User Notes:</p>
              <ul>
                <li className="guide-label">
                  Please enter your <span className="label-bold">name</span> and{" "}
                  <span className="label-bold">current gold value</span>.
                </li>
                <li className="guide-label">
                  The <span className="label-bold">current gold value</span>{" "}
                  should be entered in numeric format. For example, if your gold
                  is 24 karat, enter the value based on the care value of your
                  currency.
                </li>
                <li className="guide-label">
                  The <span className="label-bold">Nisab value</span> will be
                  calculated automatically based on the provided gold value.
                  Nisab value is the minimum amount of wealth a person must
                  possess before they are obliged to give Zakat.
                </li>
                <li className="guide-label">
                  Ensure all fields are filled correctly before proceeding.
                  After clicking the "Next" button, the process will be
                  submitted for Final <span className="label-bold">report</span>
                  .
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinalInfo;
