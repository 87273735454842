// SilverDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const SilverDetail = ({ silver, index, openSilverForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{silver.type}</div>
      <div className="zakatCalculation-value">{silver.weight}</div>
      <div className="zakatCalculation-value">{silver.valuePerGram}</div>
      <div className="zakatCalculation-value">{silver.estimatedValue}</div>
      <div className="zakatCalculation-value">{silver.zakatPayable}</div>
      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openSilverForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "silver")}
        />
      </div>
    </div>
  );
};

export default SilverDetail;
