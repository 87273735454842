// PartnershipForm.js

import React, { useState, useEffect } from "react";

const PartnershipForm = ({
  partnershipFormData,
  handlePartnershipChange,
  savePartnership,
  closePartnershipForm,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    capitalBalance: "",
    loansAdvanced: "",
    withdrawals: "",
    accumulatedProfit: "",
    zakatPercentage: "",
    zakatPayable: "",
    nettTotalWorth: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      !validationErrors.name &&
        !validationErrors.capitalBalance &&
        !validationErrors.loansAdvanced &&
        !validationErrors.withdrawals &&
        !validationErrors.accumulatedProfit &&
        !validationErrors.nettTotalWorth &&
        !!partnershipFormData.name &&
        !!partnershipFormData.capitalBalance &&
        !!partnershipFormData.loansAdvanced &&
        !!partnershipFormData.accumulatedProfit &&
        !!partnershipFormData.nettTotalWorth
    );
  }, [validationErrors, partnershipFormData]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "name":
        if (!value) {
          error = "Name is required";
        }
        break;
      case "capitalBalance":
      case "loansAdvanced":
      case "accumulatedProfit":
      case "withdrawals":
      case "zakatPayable":
      case "nettTotalWorth":
        if (!value) {
          error = "This field is required";
        } else if (isNaN(value) || parseFloat(value) < 0) {
          error = "Value must be a positive number";
        }
        break;
      default:
        break;
    }
    setValidationErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handlePartnershipChange(name, value);
    validateInput(name, value);

    // Recalculate Nett Total Worth when relevant fields change
    if (
      name === "capitalBalance" ||
      name === "loansAdvanced" ||
      name === "withdrawals" ||
      name === "accumulatedProfit"
    ) {
      const updatedNettTotalWorth = calculateNettTotalWorth(value, name);
      handlePartnershipChange("nettTotalWorth", updatedNettTotalWorth);
    }
  };

  const calculateNettTotalWorth = (updatedValue, inputName) => {
    const { capitalBalance, loansAdvanced, accumulatedProfit, withdrawals } =
      partnershipFormData;
    let updatedNetTotalWorth =
      parseFloat(capitalBalance) +
      parseFloat(loansAdvanced) +
      parseFloat(accumulatedProfit) -
      parseFloat(withdrawals);

    // If the updated input is one of the contributing fields, adjust the value accordingly
    if (
      [
        "capitalBalance",
        "loansAdvanced",
        "accumulatedProfit",
        "withdrawals",
      ].includes(inputName)
    ) {
      updatedNetTotalWorth +=
        parseFloat(updatedValue) - parseFloat(partnershipFormData[inputName]);
    }

    return isNaN(updatedNetTotalWorth) ? "" : updatedNetTotalWorth.toFixed(2);
  };

  const calculateZakatPayableforpartnership = () => {
    const nettTotalWorth = calculateNettTotalWorth();
    const zakatPercentage = 2.5; // Fixed zakat percentage
    if (!isNaN(nettTotalWorth)) {
      return (nettTotalWorth * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };

  return (
    <div className="addnewitemform-overlay">
      <div className="addnewitemform-container">
        <h2>Add Partnership</h2>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={partnershipFormData.name}
                onChange={handleInputChange}
                className={validationErrors.name ? "invalid" : ""}
              />
              {validationErrors.name && (
                <p className="error-message">{validationErrors.name}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="capitalBalance">Capital Balance:</label>
              <input
                type="text"
                id="capitalBalance"
                name="capitalBalance"
                value={partnershipFormData.capitalBalance}
                onChange={handleInputChange}
                className={validationErrors.capitalBalance ? "invalid" : ""}
              />
              {validationErrors.capitalBalance && (
                <p className="error-message">
                  {validationErrors.capitalBalance}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="loansAdvanced">Loans Advanced:</label>
              <input
                type="text"
                id="loansAdvanced"
                name="loansAdvanced"
                value={partnershipFormData.loansAdvanced}
                onChange={handleInputChange}
                className={validationErrors.loansAdvanced ? "invalid" : ""}
              />
              {validationErrors.loansAdvanced && (
                <p className="error-message">
                  {validationErrors.loansAdvanced}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="withdrawals">Withdrawals:</label>
              <input
                type="text"
                id="withdrawals"
                name="withdrawals"
                value={partnershipFormData.withdrawals}
                onChange={handleInputChange}
                className={validationErrors.withdrawals ? "invalid" : ""}
              />
              {validationErrors.withdrawals && (
                <p className="error-message">{validationErrors.withdrawals}</p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="accumulatedProfit">Accumulated Profit:</label>
              <input
                type="text"
                id="accumulatedProfit"
                name="accumulatedProfit"
                value={partnershipFormData.accumulatedProfit}
                onChange={handleInputChange}
                className={validationErrors.accumulatedProfit ? "invalid" : ""}
              />
              {validationErrors.accumulatedProfit && (
                <p className="error-message">
                  {validationErrors.accumulatedProfit}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="nettTotalWorth">Net Total Worth:</label>
              <input
                type="text"
                id="nettTotalWorth"
                name="nettTotalWorth"
                value={partnershipFormData.nettTotalWorth}
                onChange={handleInputChange}
                className={validationErrors.nettTotalWorth ? "invalid" : ""}
              />
              {validationErrors.nettTotalWorth && (
                <p className="error-message">
                  {validationErrors.nettTotalWorth}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="zakatPercentage">Zakat Percentage:</label>
              <input
                type="text"
                id="zakatPercentage"
                name="zakatPercentage"
                value="2.5%"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="zakatPayable">Zakat Payable:</label>
              <input
                type="text"
                id="zakatPayable"
                name="zakatPayable"
                value={calculateZakatPayableforpartnership()}
                readOnly
              />
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={savePartnership}
              disabled={!isFormValid}
              className="additem-button"
            >
              Save
            </button>
            <button
              type="button"
              onClick={closePartnershipForm}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
          <div className="user-notes">
            <p className="guideline-heading">Guidelines:</p>
            <ul>
              <li className="guide-label">
                <span className="label-bold">Name:</span> Enter the name of the
                partnership.
              </li>
              <li className="guide-label">
                <span className="label-bold">Capital Balance:</span> Enter the
                total balance of capital invested in the partnership.
              </li>
              <li className="guide-label">
                <span className="label-bold">Loans Advanced:</span> Enter the
                total amount of loans advanced by the partnership.
              </li>
              <li className="guide-label">
                <span className="label-bold">Withdrawals:</span> Enter the total
                amount of withdrawals made from the partnership.
              </li>
              <li className="guide-label">
                <span className="label-bold">Accumulated Profit:</span> Enter
                the total accumulated profit of the partnership.
              </li>
              <li className="guide-label">
                <span className="label-bold">Net Total Worth:</span> This field
                will automatically calculate based on the provided data.
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PartnershipForm;
