// LandDetail.js 

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const LandDetail = ({ land, index, openLandForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{land.type}</div>
    
      <div className="zakatCalculation-value">{land.estimatedValue}</div>
      <div className="zakatCalculation-value">{land.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openLandForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "land")}
        />
      </div>
    </div>
  );
};

export default LandDetail;
