import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const BusinessDetail = ({
  business,
  index,
  openBusinessForm,
  openDeleteConfirmation,
}) => {
  return (
    <div key={index} className="zakatCalculation-row entry-separation-line">
      <div className="zakatCalculation-value">{business.name}</div>
      <div className="zakatCalculation-value">{business.saleableStock}</div> {/* Value of Saleable Stock */}
      <div className="zakatCalculation-value">{business.damagedStock}</div> {/* Value of Damaged / Dead Stock */}
      <div className="zakatCalculation-value">{business.creditSales}</div> {/* Amount Receivable from Credit Sales */}
      <div className="zakatCalculation-value">{business.payableToSuppliers}</div> {/* LESS: Amount Payable to Suppliers */}
      <div className="zakatCalculation-value">{business.badDebts}</div>
      <div className="zakatCalculation-value">{business.totalStockValue}</div>
    
      <div className="zakatCalculation-value">{business.zakatPayable}</div>
      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openBusinessForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "business")}
        />
      </div>
    </div>
  );
};

export default BusinessDetail;
