// GoldDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const GoldDetail = ({ gold, index, openGoldForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{gold.type}</div>
      <div className="zakatCalculation-value">{gold.weight}</div>
      <div className="zakatCalculation-value">{gold.valuePerGram}</div>
      <div className="zakatCalculation-value">{gold.estimatedValue}</div>
      <div className="zakatCalculation-value">{gold.zakatPayable}</div>
      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openGoldForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "gold")}
        />
      </div>
    </div>
  );
};

export default GoldDetail;
