// InvestmentsDetail.js

import React from "react";
import { MdDelete, MdEdit } from "react-icons/md";

const InvestmentsDetail = ({ investments, index, openInvestmentsForm, openDeleteConfirmation }) => {
  return (
    <div key={index} className="zakatCalculation-row entryseparation-line">
      <div className="zakatCalculation-value">{investments.type}</div>
    
      <div className="zakatCalculation-value">{investments.estimatedValue}</div>
      <div className="zakatCalculation-value">{investments.zakatPayable}</div>

      <div className="zakatCalculation-value">
        <MdEdit
          className="zakatCalculation-edit-icon"
          onClick={() => openInvestmentsForm(index)}
        />
        <MdDelete
          className="zakatCalculation-delete-icon"
          onClick={() => openDeleteConfirmation(index, "investments")}
        />
      </div>
    </div>
  );
};

export default InvestmentsDetail;
