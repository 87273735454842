import React, { useState, useEffect } from "react";

const GeneralLiabilitiesForm = ({
  liabilitiesFormData,
  handleLiabilitiesChange,
  saveLiabilities,
  closeLiabilitiesForm,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    loansFromFriends: "",
    loansFromCompany: "",
    rentPayable: "",
    zakatPayable: "",
    totalLiabilities: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      !validationErrors.loansFromFriends &&
        !validationErrors.loansFromCompany &&
        !validationErrors.rentPayable &&
        !validationErrors.zakatPayable &&
        !validationErrors.totalLiabilities &&
        !!liabilitiesFormData.loansFromFriends &&
        !!liabilitiesFormData.loansFromCompany &&
        !!liabilitiesFormData.rentPayable &&
        !!liabilitiesFormData.totalLiabilities
    );
  }, [validationErrors, liabilitiesFormData]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "loansFromFriends":
      case "loansFromCompany":
      case "rentPayable":
      case "totalLiabilities":
        if (!value) {
          error = "This field is required";
        } else if (isNaN(value) || parseFloat(value) < 0) {
          error = "Value must be a positive number";
        }
        break;
      default:
        break;
    }
    setValidationErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleLiabilitiesChange(name, value);
    validateInput(name, value);

    // Recalculate Total Liabilities when any relevant fields change
    if (
      name === "loansFromFriends" ||
      name === "loansFromCompany" ||
      name === "rentPayable"
    ) {
      const loansFromFriends = parseFloat(
        e.target.name === "loansFromFriends"
          ? value
          : liabilitiesFormData.loansFromFriends
      );
      const loansFromCompany = parseFloat(
        e.target.name === "loansFromCompany"
          ? value
          : liabilitiesFormData.loansFromCompany
      );
      const rentPayable = parseFloat(
        e.target.name === "rentPayable"
          ? value
          : liabilitiesFormData.rentPayable
      );

      const totalLiabilities =
        loansFromFriends + loansFromCompany + rentPayable;

      handleLiabilitiesChange("totalLiabilities", totalLiabilities.toFixed(2));
      handleLiabilitiesChange(
        "zakatPayable",
        calculateZakatPayable(totalLiabilities)
      );
    }
  };

  const calculateZakatPayable = (totalLiabilities) => {
    const zakatPercentage = 2.5; // Fixed zakat percentage
    if (!isNaN(totalLiabilities)) {
      return (totalLiabilities * (zakatPercentage / 100)).toFixed(2);
    }
    return "";
  };

  return (
    <div className="addnewitemform-overlay">
      <div className="addnewitemform-container">
        <h2>Add General Liabilities</h2>
        <form>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="loansFromFriends">
                Loans from Friends / Credit Card:
              </label>
              <input
                type="text"
                id="loansFromFriends"
                name="loansFromFriends"
                value={liabilitiesFormData.loansFromFriends}
                onChange={handleInputChange}
                className={validationErrors.loansFromFriends ? "invalid" : ""}
              />
              {validationErrors.loansFromFriends && (
                <p className="error-message">
                  {validationErrors.loansFromFriends}
                </p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="loansFromCompany">Loans from Company:</label>
              <input
                type="text"
                id="loansFromCompany"
                name="loansFromCompany"
                value={liabilitiesFormData.loansFromCompany}
                onChange={handleInputChange}
                className={validationErrors.loansFromCompany ? "invalid" : ""}
              />
              {validationErrors.loansFromCompany && (
                <p className="error-message">
                  {validationErrors.loansFromCompany}
                </p>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="rentPayable">Rent Payable:</label>
              <input
                type="text"
                id="rentPayable"
                name="rentPayable"
                value={liabilitiesFormData.rentPayable}
                onChange={handleInputChange}
                className={validationErrors.rentPayable ? "invalid" : ""}
              />
              {validationErrors.rentPayable && (
                <p className="error-message">{validationErrors.rentPayable}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="totalLiabilities">Total Liabilities:</label>
              <input
                type="text"
                id="totalLiabilities"
                name="totalLiabilities"
                value={liabilitiesFormData.totalLiabilities}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="zakatPercentage">Zakat Percentage:</label>
              <input
                type="text"
                id="zakatPercentage"
                name="zakatPercentage"
                value="2.5%"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="zakatPayable">Zakat Payable:</label>
              <input
                type="text"
                id="zakatPayable"
                name="zakatPayable"
                value={liabilitiesFormData.zakatPayable}
                readOnly
              />
            </div>
          </div>
          <div className="button-group">
            <button
              type="button"
              onClick={saveLiabilities}
              disabled={!isFormValid}
              className="additem-button"
            >
              Save
            </button>

            <button
              type="button"
              onClick={closeLiabilitiesForm}
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
          <div className="user-notes">
            <p className="guideline-heading">Guidelines:</p>
            <ul>
              <li className="guide-label">
                <span className="label-bold">
                  Loans from Friends / Credit Card:
                </span>{" "}
                Enter the amount of loans received from friends or credit cards.
                If no value is provided, enter 0.
              </li>
              <li className="guide-label">
                <span className="label-bold">Loans from Company:</span> Enter
                the amount of loans received from the company. If no value is
                provided, enter 0.
              </li>
              <li className="guide-label">
                <span className="label-bold">Rent Payable:</span> Enter the
                amount of rent payable. If no value is provided, enter 0.
              </li>
              <li className="guide-label">
                <span className="label-bold">Total Liabilities:</span> This
                field will automatically calculate based on the provided data.
              </li>
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GeneralLiabilitiesForm;
